.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 5%;
    z-index: 1;
    // --webkit-mix-blend-mode: difference;
    // mix-blend-mode: difference;
    justify-content: space-between;
    .nav__item {
        text-decoration: none;
        a {
            font-size: 14px;
            color: #fff;
            text-decoration: none;
        }
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        justify-content: center;
    }
}