
.cursor {
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    z-index: 1;
    transition: 0.5s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
        0.2s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
    user-select: none;
    pointer-events: none;
    transform: scale(0.8);
    display: none;
}

.cursor::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    background-image: url("http://mirkozeppieri.emanuelepapale.com/wp-content/uploads/2018/07/project-hover-cursor.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    opacity: 0;
}

// .cursor.active {
//     opacity: 1;
//     transform: scale(12);
// }

.cursor.active::before {
    opacity: 1;
}

.cursor-follower {
    position: absolute;
    background: var(--color-cursor);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    z-index: 1;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform;
        // 0.4s cubic-bezier(0.75, -1.27, 0.3, 2.33) opacity;
    user-select: none;
    pointer-events: none;
    transform: translate(4px, 4px);
    &:after {
        content: "";
        position: absolute;
        color: #fff;
        width: 10px;
        height: 10px;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        background-color: var(--color-follower);
        border-radius: 50%;
    }
    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.cursor-follower.active {
    opacity: 1;
    transform: scale(2);
}