.loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    padding: 0 5%;
    padding-top: 35px;
    width: 100%;
    display: flex;
    .letters div {
        color: #fff !important;
    }
    @media only screen and (max-width: 768px) {
        .logo {
            margin: 0;
            margin-top: 5.5vw;
        }
        padding: 0 3%;
        height: auto;
        text-align: center;
        justify-content: center;
    }
}

.header {
    width: 100%;
    height: 100px;
    padding: 0 5%;
    padding-top: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    // mix-blend-mode: difference;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    @media only screen and (max-width: 768px) {
        padding: 0 3%;
        flex-direction: column;
        height: auto;
        text-align: center;
    }
}

.nav {
    display: flex;
    // background-color: red;
    &__item {
        font-size: 18px;
        text-transform: uppercase;
        cursor: pointer;
        margin: 0 15px;
        &:first-child {
            margin-left: 0;
        }
    }
    &__privacy {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        // padding: 0 3%;
        height: auto;
        text-align: center;
        // margin: 3vw auto;
        &__item {
            font-size: 3vw;
            margin: auto 1vw;
        }
    }
}

.intro {
    width: 30vw;
    position: fixed;
    top: 91px;
    left: 5%;
    font-size: 13px;
    line-height: 24px;
    // mix-blend-mode: difference;
    // --webkit-mix-blend-mode: difference;
    opacity: 0;
    z-index: 1;
    &-2 {
        opacity: 0;
    }
    @media only screen and (max-width: 768px) {
        display: none;
    }
}