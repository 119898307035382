.clients {
    width: 100vw;
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 997;
    &__title {
        width: 100vw;
        // padding: 30vw 5vw;
        padding: 0 10vw;
        margin-bottom: 7vh;
        font-size: 2.5vw;
        color: #000;
    }
    &__list {
        margin: 0 auto;
        display: grid;
        grid-template-columns: auto auto auto auto;
        padding: 0 10vw;
        &-item {
            height: 10vh;
            min-height: 100px;
            background: url(../img/clients/adjarabet.png) no-repeat center;

            filter: grayscale(1);

            margin: 5vh 0;
            transition: all .3s ease;

            
            // filter: grayscale(100%);
            &:nth-child(1) {
                background: url(../img/clients/blox.png) no-repeat center;
            }
            &:nth-child(2) {
                background: url(../img/clients/bog.svg) no-repeat center;
            }
            &:nth-child(3) {
                background: url(../img/clients/domino.png) no-repeat center;
            }
            &:nth-child(4) {
                background: url(../img/clients/georgia.png) no-repeat center;
            }
            &:nth-child(5) {
                background: url(../img/clients/liberty.png) no-repeat center;
            }
            &:nth-child(6) {
                background: url(../img/clients/phubber.png) no-repeat center;
            }
            &:nth-child(7) {
                background: url(../img/clients/satibe.png) no-repeat center;
            }
            &:nth-child(8) {
                background: url(../img/clients/touch.png) no-repeat center;
            }
            background-size: 45% !important;

            &:hover {
                filter: grayscale(0);
                // background-size: 55% !important;
            }
        }
    }

    @media only screen and (min-width: 1500px) {
        &__list {
            &-item {
                height: 15vh;
            }
        }
    }
    
    @media only screen and (max-width: 768px) {
        &__title {
            margin-bottom: 5vh;
            font-size: 3vw;
        }
        
        &__list {
            grid-template-columns: auto auto auto;
            &-item {
                height: 12vh;
                min-height: inherit;
                margin: 0;
                background-size: 60% !important;
            }
        }
    }
}