.services {
    display: table;
    &-container {
        display: grid;
        grid-template-columns: auto auto;
    }
    &-content {
        height: auto;
    }
    &Item {
        display: flex;
        align-items: flex-start;
        padding: 0 5vw;
        margin-bottom: 15vh;
        &__title {
            font-weight: bold;
            font-size: 1.7vw;
            height: 7vh;
            display: flex;
            align-items: center;
            text-transform: uppercase;
        }
        &__desc {
            font-size: 1vw;
            line-height: 1.7vw;
        }
        &__img {
            margin-right: 2vw;
            width: 5vw;
            height: 7vh;
        }
    }
    &__title {
        text-align: center;
        padding: 5vh 0;
        font-size: 3.5vw;
        font-weight: bold;
        margin-bottom: 7vh;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 768px) {
        &-container {
            grid-template-columns: auto;
        }
        &__title {
            font-size: 4vw;
            margin-bottom: 5vh;
        }
        &Item {
            margin-bottom: 5vh;
            &__desc {
                font-size: 3vw;
                line-height: inherit;
            }
            &__img {
                width: 10vw;
            }
            &__title {
                font-size: 3.5vw;
            }
        }
    }

}