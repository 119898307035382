.methods-title-container {
    height: 100vh;
    padding-bottom: 50vh;
}

.methodsNavPage {
    position: relative;
    z-index: 9;
}

.methodsPage {
    position: relative;
    z-index: 9;
}

.methods {
    &__title {
        width: 100vw;
        height: 70vh;
        // margin: 15vw 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        opacity: 0;
        
        div {
            position: relative;
            top: 10vh;
            opacity: 0;
            &.shown {
                top: 0%;
                opacity: 1;
            }

            height: 25.3%;
            &:nth-child(1) {
                background: url(../img/method-1.svg) no-repeat;
            }
            &:nth-child(2) {
                background: url(../img/method-2.svg) no-repeat;
                transition-delay: 0.1s;
            }
            &:nth-child(3) {
                background: url(../img/method-3.svg) no-repeat;
                transition-delay: 0.2s;
            }
            background-size: auto 100% !important;
            background-position: left 10vh center !important;
            transition: all 0.6s ease;
        }
    }

    // Methods
    &Nav {
        display: flex;
        position: absolute;
        top: 30%;
        /* left: 50%; */
        /* transform: translateX(-50%); */
        left: 0;
        z-index: 999;
        width: 100vw;
        display: flex;
        justify-content: center;
        &__item {
            color: #000;
            cursor: pointer;
            font-size: 1.2vw;
            margin: 0 1vw;
            &.active {
                // transform: scale(1.1);
                font-size: 1.2vw;
                font-weight: bold;
            }
        }
    }
    
    &Section {
        display: flex;
        align-items: center;
        justify-content: center;
        
        width: 100vw;
        padding: 0 15vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2vw;
        color: #000;
        text-align: center;

        &-last {
            width: 1vw;
        }
    }
    @media only screen and (max-width: 768px) {
        &__title {
            div {
                height: 33.33%;
                background-size: 90% 30% !important;
                background-position: left 5vw center !important;
            }
        }
        &Section {
            width: 100vw;
            padding: 0 15vw;
            height: 100vh;
            font-size: 3vw;
        }
        &Nav {
            &__item {
                font-size: 2.5vw;
                margin: 0 1vw;
                &.active {
                    // transform: scale(1.1);
                    font-size: 2.5vw;
                    font-weight: bold;
                }
            }
        }
    }
}