.privacy {
    margin-top: 100px;
    padding: 0 5%;
    &__title {
        font-size: 24px;
    }
    &Desc {
        &__title {
            font-size: 18px;
            font-weight: bold;
            margin-top: 20px;
            margin-bottom: 10px;
        }
        &__text {
            font-size: 14px;
            line-height: 22px;
        }
    }
}


.privacyPage {
    .loader {
        justify-content: center;
    }
    .header {
        justify-content: center;
    }
}