.contact {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 9;
    margin: 30vh auto;
    &-container {
        width: 70%;
        margin: 0 auto;
    }
    &__text {
        font-size: 24px;
        text-align: left;
        line-height: 44px;
        &--gray {
            opacity: .3;
            font-size: 18px;
            margin-top: 50px;
            margin-bottom: 10px;
        }
    }
    &__email {
        a {
            display: block;
            font-size: 34px;
            color: #fff;
        }
    }

    @media only screen and (max-width: 768px) {
        &__text {
            font-size: 4vw;
            &--gray {
                opacity: 0.3;
                font-size: 3.5vw;
                margin-top: 6vw;
                margin-bottom: 0vw;
            }
        }
        &__email {
            a {
                font-size: 7vw;
            }
        }
    }
}