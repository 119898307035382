@import './_fonts.scss';

@import './_base.scss';

@import './_project.scss';

@import './_header.scss';

@import './_footer.scss';

@import './_logo.scss';

@import './_tail.scss';

@import './_about.scss';

@import './_clients.scss';

@import './_methods.scss';

@import './_services.scss';

@import './_contact.scss';

@import './pulse';

@import './cursor';

@import './privacy';

.sss {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;    
    justify-content: center;
}

.sss2 {
    // height: 100vh;
    background-color: bisque;
    overflow: auto;
    .block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 800px;
        height: 50vh;
        border: 1px solid blue;
        color: blue;
        margin-right: 200px;
    }
}

// body {
//     overflow: hidden;
// }
.word {
    display: inline-block;
}


body {
    overflow-x: hidden;
}

.section {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5vw;
    box-sizing: border-box;
}

.section__cards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
}

.section__card {
    border-radius: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
}
.section__card + .section__card {
    // margin-left: 5vw;
}

.section__title {
color: #1b2b34;
}

.section[data-type=horizontal] {
overflow-x: hidden;
}

@keyframes moveTop{
    0%{transform:translateY(0) translateX(-50%)}
    25%{transform:translateY(0) translateX(-50%)}
    50%{transform:translateY(-6px) translateX(-50%)}
    to{transform:translateY(0) translateX(-50%)}
}


.icon-mouse {
    width: 28px;
    top: 4rem;
    -webkit-animation: moveTop 1.5s infinite forwards;
    animation: moveTop 1.5s infinite forwards;
    @media only screen and (max-width: 768px) {
		display: none;
	}
}