.tail {
    position: absolute;
    width: 10000px;
    height: 100vh;
    top: 0;
    left: 0;
}

.mountain-container {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    position: fixed;
    top: 0;
    left: 0;
    // z-index: 9;
}
