.logo {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    // opacity: 0;
    @media only screen and (max-width: 768px) {
        margin: 5.5vw auto;
    }
}

.logo > * { box-sizing: border-box; }

.letters > * {
    font-size: 34px;
    // color: #fff;
    position: relative;
    display: inline-block;
    font-family: 'NeueMetanaBold', sans-serif;
    // --webkit-mix-blend-mode: difference;
    // mix-blend-mode: difference;
    color: #fff;
    
    @media only screen and (max-width: 768px) {
        font-size: 5vw;
    }
    cursor: pointer;
}

.letters div:nth-child(3), .letters div:nth-child(5) {
    font-size: 44px;
    @media only screen and (max-width: 768px) {
        font-size: 6.5vw;
    }
}

.letters div:nth-child(4) {
    font-size: 54px;
    @media only screen and (max-width: 768px) {
        font-size: 8vw;
    }
}