.projects .section__card {
    width: 35vw;
    margin-right: 15vw;
    @media only screen and (max-width: 768px) {
        width: 70vw;
        margin-right: 20vw;
    }
    @media only screen and (min-width: 1500px) {
        width: 30vw;
        margin-right: 10vw;
    }

    &--hidden {
        width: 100%;
        margin-right: 0;
        padding: 0 20%;
        &:nth-child(1n) {
            margin-right: 10%;
        }
        .project__item--hidden {
            justify-content: center;
            align-items: center;
            font-size: 24px;
            text-align: center;
            line-height: 46px;
        }
    }

    @media only screen and (max-width: 768px) {
        margin-right: 10vw;
        &--hidden {
            div {
            }
            width: 100%;
            padding: 0 10%;
            .project__item--hidden {
                justify-content: center;
                align-items: center;
                font-size: 2.5vw;
                text-align: center;
                line-height: 5vw;
            }
        }
    }
}

.welcomeinfo div {
    // width: 0;
    
    // overflow: hidden;
    white-space: nowrap;

    position: relative;

    .word {
        opacity: 0;
    }

    // span {
    //     width: 100%;
    //     background-color: #000;
    //     position: absolute;
    //     height: 100%;
    //     right: 0;
    //     top: 0;
    // }
    
    // animation: typing 1.5s both;
    // animation-delay: 1.3s;

    // &:nth-child(2n) {
    //     animation-delay: 2s;
    // }
    // &:nth-child(3n) {
    //     animation-delay: 2.8s;
    // }
}

// @keyframes typing {
//     from { width: 0 }
//     to { width: 100% }
// }

.projectsPage {
    overflow-y: hidden;
    height: -webkit-fill-available;
}

.project {
	display: flex;
	margin-left: 12vw;
	padding-right: 12vw;
    // height: calc(100vh - 200px);

    &:nth-child(even) {
        padding-top: 10vh;
    }
    &__item {
        // margin-right: 10vw;
        margin: 0;
        padding: 0;
        z-index: 999;
        &--hidden {
            // opacity: 0;
        }
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        
        &-img {
            width: 80%;
            // height: 22rem;
            height: 220px;
            // overflow: hidden;
            transition: all .3s ease;
            img {
                filter: grayscale(80%);
            }
            &.cover {
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    // transform: inherit !important;
                }
            }
            // margin-left: 20vw;
        }
        &-imginner {
            background-size: contain;
            background-position: 50% 0;
            width: 100%;
            // height: calc(100% + 14vh);
            // margin-top: -7vh;
            will-change: transform;
            
        }
        &-caption {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            position: relative;
            margin-top: 1rem;
        }
        &-title {
            // font-size: 3.5rem;
            font-size: 2rem;
            font-family: 'NeueMetanaBold', sans-serif;
        }
        &-desc {
            margin-top: 0.7rem;
            font-size: 1.2rem;
            color: #808080;
        }

        &:hover {
            .project__item-img {
                transform: scale(1.1) rotate(10deg) translateY(-10px);
            }
            img {
                filter: grayscale(0);
            }
        }
    }
    
    @media only screen and (max-width: 768px) {
        &__item {
            &-img {
                height: 30vh;
            }
        }
    //     display: block;
    //     &__item {
    //         // margin-left: 0;
    //         // margin-right: 0;
    //         // margin: 0 auto;
    //         width: 60vw;
    //         // margin-bottom: 5rem;
    //         &-title {
    //             font-size: 5vw;
    //         }
    //         &-img {
    //             width: 100%;
    //             height: 24vh;
    //             // margin: 0 auto;
    //         }
    //         &-desc {
    //             margin-top: 1vh;
    //             height: 2.5vw;
    //         }
    //     }
    }

    // Height issue
    // @media only screen and (max-height: 800px) {
    //     &__item {
    //         &-title {
    //             font-size: 4.5vw;
    //         }
    //         &-desc {
    //             margin-top: 0.3rem;
    //             font-size: 3vw;
    //             color: #808080;
    //         }
    //     }
    // }
}

.content,
.horizontalScrolling .section-inner,
.horizontalScrolling, .section__cards, .methodsNavPage {
    will-change: transform;
}