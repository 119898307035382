*,
*::after,
*::before {
	margin: 0;
    padding: 0;
	box-sizing: border-box;
}

:root {
	font-size: 12px;
	--color-bg: #000;
	--color-text: #fff;
	--color-cursor: rgba(255, 255, 255, 0.1);
	--color-follower: #fff;
}

body {
	margin: 0;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: 'NeueMetanaRegular', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition: background-color 1s ease;
	cursor: none;
}

html,body {
	-webkit-overflow-scrolling : touch !important;
	overscroll-behavior-y: none !important;
	// overflow: auto !important;
}

.loading:after {
	content: "";
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #000;
	z-index: 999;
}

.loading .nav {
	display: none;
}

.loading .letters div {
	color: #000;
}

// header,footer, .intro {
// 	display: none !important;
// }


.nav {
	// overflow: hidden;
}

.lnk {
	overflow: hidden;
	height: 1em;
	color: #797878;
	font-weight: 400;
	
	span {
		position: relative;
		display: inline-block;
		transition: all .3s ease;
		&::before {
			position: absolute;
			top: 100%;
			content: attr(data-hover);
			transform: translate3d(0,0,0);
		}
	}
	&:hover, &.active{
		span{
			transform: translateY(-100%);
		}
	}
	@media only screen and (max-width: 768px) {
		&:hover, &.active{
			span {
				transform: inherit;
				font-weight: 800;
			}
		}
	}
}

a {
	color: #fff;
}